/* .apexcharts-menu-item.exportCSV {
    display: none;
} */

/* .apexcharts-menu-item.exportSVG {
    display: none;
} */
@page {
    size: A4;
    margin: 0;
}

/* @page sectionChart {
    margin: 20mm 0 10mm 0;
} */

@media print {
    body * {
        visibility: hidden;
    }

    .section-to-print,
    .section-to-print * {
        visibility: visible;
    }

    .section-to-print {
        margin: 10mm 0 10mm 0;
        position: absolute;
        left: 0;
        top: 0;

    }

    .section-to-print-dlt,
    .section-to-print-dlt * {
        visibility: visible;
    }

    .section-to-print-dlt {
        size: A4;
        margin: 0;
        position: absolute;
        left: 0;
        top: 0;

    }

    /* .section-to-print-left {
        padding-left: 2mm;
    } */
}

thead {
    display: table-header-group;
}

tfoot {
    display: table-footer-group;
}