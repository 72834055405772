body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.is-button {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

}

@font-face {
  font-family: 'Sarabun';
  src: URL('./fonts/Sarabun/Sarabun-New.ttf') format('truetype');
}

.icon-car {
  background: transparent !important;
  border: none !important;
  background-image: url("./img/car_icon.svg") !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  z-index: 2 !important;
}


.icon-car-focus {
  background: transparent !important;
  border: none !important;
  background-image: url("./img/car_icon_focus.svg") !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  z-index: 3 !important;
}

.icon-car-offline {
  background: transparent !important;
  border: none !important;
  background-image: url("./img/car_icon_offline.svg") !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  z-index: 2 !important;

}

.icon-car-center {
  display: block;
  margin-top: 14px;
  margin-left: 9px;
}


.icon-marker-focus {
  background: transparent !important;
  border: none !important;
  background-image: url("./img/car_icon.svg") !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}


.back-tooltip-class {
  z-index: 2 !important;
}

.front-tooltip-class {
  z-index: 3 !important;
}

.label-maker-focus {
  z-index: 3 !important;
}

.label-maker {
  z-index: 2 !important;
}


.icon-maker-station {
  background: transparent !important;
  border: none !important;
  /* background-image: url("./img/station_marker.svg") !important; */
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.icon-maker-station-center {
  display: block;
  margin-top: 8px;
  margin-left: 9px;
}

.icon-div-marker {
  background: transparent !important;
  border: none !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.tooltip-color {
  background-color: #23313e !important;
  color: #fff;
}

.tooltip-label {
  z-index: 999;
}

.label-popup .leaflet-popup-content-wrapper {
  border-radius: 0px;
}

.tableRow-active {
  background-color: whitesmoke;
}


.leaflet-draw-section {
  display: none
}

.leaflet-draw-toolbar {
  display: none
}

.leaflet-draw-tooltip {
  display: none
}

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
  border-radius: 100%;
  width: 15px !important;
  height: 15px !important;
  margin-left: -7.5px !important;
  margin-top: -7.5px !important;
  font-size: 10%;
}

.leaflet-draw-guide-dash {
  width: 5px;
  height: 5px;
  border-radius: 100%;
}

/* .leaflet-pm-action {
  display: none !important;
} */
.leaflet-pm-tooltip {
  display: none !important;
}



.polyline-top {
  z-index: 9999 !important;
}

/* .back-tooltip {
  z-index: -100 !important;
} */

.leaflet-tooltip-pane {
  z-index: 599 !important;
}

.leaflet-marker-pane {
  z-index: 600 !important;
}




/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
/* input[type="number"] {
  -moz-appearance: textfield;
} */



.cluster-icon {
  background-color: rgb(124, 122, 122);
  height: 2em;
  width: 2em;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1.2rem;
  box-shadow: 0 0 0px 2px #fff;
  text-align: center;
}
.cluster-icon-text {
  text-align: center;
  font-size: 0.8rem;
}


.my-label {
  position: absolute;
  font-size: 20px;
  z-index: 3 !important;
}